import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PropertyData_Range, PropertyData_Special, PropertyData_Thrown, PropertyData_Versatile, PropertyDescriptions, iWeaponData, WeaponProperties } from '../gameData/weapons';
import { capitaliseFirstLetter } from '../Util';
import Modal from 'react-modal';
import { CommonButton, CommonHeading } from './Common';
import { FaTimes } from 'react-icons/fa'

interface iWeaponDisplay { 
    data: iWeaponData;
    onRemove?: ()=>void;
}
const WeaponDisplayWrapper = styled.div`
    padding: 5px;
    position: relative;

    .title {
        font-size: 14px;
        font-weight: 600;
    }

    .icon {
        position: absolute;
        left: 1%;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .underlined {
        text-decoration: underline;
        display: inline-block;
    }

    .cost-damage-weight {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        margin: 5px 0px;
    }

    &:nth-child(2n) {
        background-color: #00000016;
    }
    &:nth-child(2n+1) {
        background-color: #4137373d;
    }
`
export const WeaponDisplay = ({data, onRemove=()=>{}}: iWeaponDisplay) => {
    /*
        Creates a "PropertyDisplay" component for each 'property' on the weapon. Eg. Light, Thrown, Finesse
    */
    const propertiesJsx = useMemo(() => {
        let {properties, properties_data} = data;
        if(!properties) return [];
        properties_data = properties_data || {}

        let new_jsx: JSX.Element[] = [];
        for(let property of properties) {
            let property_data = (properties_data as any)[property]
            new_jsx.push(<PropertyDisplay key={property} property={property} data={property_data}/>)
        }
        return new_jsx
    }, [data])

    return <WeaponDisplayWrapper>
        <FaTimes className='icon' onClick={onRemove}/>
        <div className='title'>
            {data.name}
        </div>
        <div className='cost-damage-weight'>
            <div>{data.cost[0]} {data.cost[1]}</div>
            {data.damage_type && (
                <div>{data.damage_dice}{data.damage_die} {capitaliseFirstLetter(data.damage_type)}</div>
            ) || <div/>}
            <div>{data.weight}</div>
        </div>
        {propertiesJsx.length > 0 && (<div className='properties'>
            <b>Tags: </b>
            {propertiesJsx}
        </div>)}
    </WeaponDisplayWrapper>
}

const PropertyModal = styled(Modal)`
    height: 100%;
    width: 100%;

    display: grid;
    justify-content: center;
    
    .inner {
        width: fit-content;
        max-width: 80%;
        height: auto;
        margin: auto;
        background-color: #E0C9A6;
        padding: 20px;
        border: 1px solid;
        border-radius: 5px;
        text-align: center;
    }
    
    .description {
        margin: 20px 0px;
    }

    ${CommonButton} {
        display: block;
        margin: auto;
    }


`

interface iPropertyDisplay {
    property: WeaponProperties,
    data?: PropertyData_Thrown | PropertyData_Versatile | PropertyData_Range | PropertyData_Special

}
const PropertyDisplay = ({property, data}: iPropertyDisplay) => {

    const [modalOpen, setModalOpen] = useState(false);

    
    const openModal = () => {
        if(modalOpen === false) setModalOpen(true);
    }

    const closeModal = () => {
        if(modalOpen === true) setModalOpen(false)
    }


    const [propertyDescription, setPropertyDescription] = useState<string>()

    const ToggleModal = () => {
        console.log("Modal being toggled!");
        setModalOpen(state => !state)
    }

    //If the property to be displayed has special data, render a string to represent this data.
    const dataString = useMemo(() => {
        if(!data) return '';

        switch(property) {
            case 'Thrown':
                data = data as PropertyData_Thrown;
                return ` (${data.normalRange}/${data.longRange})`
            case 'Range':
                data = data as PropertyData_Range;
                return ` (${data.normalRange}/${data.longRange})`
            case 'Versatile':
                data = data as PropertyData_Versatile;
                return ` (${data.damage_dice}${data.damage_die})`
            case 'Special':
                //special handled seperately through the below useEffect
            default:
                return ''
        }
    }, [property, data])

    useEffect(() => {
        if(property !== 'Special') {
            return setPropertyDescription(PropertyDescriptions[property])
        }
        console.log("Data is ", data)

        data = data as PropertyData_Special;
        setPropertyDescription(data.description)
    }, [property, data])

    return <span onClick={openModal} className='property-text'>
        <span className='underlined'>
            {property}{dataString}
        </span>
        ,{' '}
        <PropertyModal
            isOpen={modalOpen}
            onRequestClose={()=>{}}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className='inner'>
                <CommonHeading>{property}</CommonHeading>
                <div className='description'>
                    {propertyDescription}
                </div>
                <CommonButton onClick={closeModal}>Close</CommonButton>
            </div>
        </PropertyModal>
    </span>
}