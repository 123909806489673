import React from 'react';
import styled from 'styled-components'

const ACWrapper = styled.div`
    background-image: url("ac_shield.png");
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 30px;
    width: fit-content;
    padding: 30px;
    background-position: center; 

    div {
        padding-bottom: 8px;
    }
`
interface iACDisplay {
    ACValue: number;
}

export const ACDisplay = ({ACValue}: iACDisplay) => {
    return (
        <ACWrapper>
            <div>{ACValue}</div>
        </ACWrapper>
    )
}