import React, { createRef, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { characterData } from './CharacterData';
import { CharacterSheet } from './CharacterSheet';
import { Character } from './gameObjects/Character'

import { weaponData } from './gameData/weapons';
import { CharacterContext } from './Contexts/CharacterContext';


const App = () => {
    const pathname = window.location.pathname.slice(1)
    const characterDataUsed = characterData[pathname]

    //we use a special state here which we can use to force a re-rendering of the page
    const [characterUpdated, setCharacterUpdated] = useState({})

    //useMemo(function to return the value, array of conditions that say when to rerun the function)
    const character = useMemo(() => {

        if(!characterDataUsed) return;

        let newCharacter = new Character({
            data: characterDataUsed,

            //pass through triggerRefresh which sets 'characterUpdate' to a NEW object.
            //  this triggers a re-render because the state changed.
            triggerRefresh: () => {setCharacterUpdated({})}
        })

        //get updates from the browsers local storage. Otherwise set updates to an empty object.
        let storageString = localStorage.getItem(newCharacter.data.name);
        let updates = storageString ? JSON.parse(storageString) : {}

        if(updates.level) newCharacter.currentLevel = updates.level;
        if(updates.health) newCharacter.currentHealth = updates.health
        if(updates.weapons) {
            newCharacter.weapons = updates.weapons.split('|').map((weapon_ref: string) => weaponData[weapon_ref])
        }
        if(updates.currentGauges) {
            let currentGaugeValues = JSON.parse(updates.currentGauges);
            for(let [name, value] of Object.entries(currentGaugeValues)) {
                newCharacter.gaugesCurrentValue[name] = value as number;
            }
        }

        return newCharacter;

    }, [characterData, setCharacterUpdated])

    useEffect(() => {
        if(!character) return;

        console.log("Character updated")
        //if the character was updated, save character state to the local browser storage.
        //   this allows the data to persist between sessions.

        localStorage.setItem(character.data.name, JSON.stringify({
            level: character.currentLevel,
            health: character.currentHealth,
            weapons: character.weapons.map(w => w.ref).join('|'),
            currentGauges: JSON.stringify(character.gaugesCurrentValue)
        }))
    }, [characterUpdated, character])

    if(!character) return <div>No Character found for {pathname}</div>

    return (
        <CharacterContext.Provider value={{character: character, characterUpdated: characterUpdated}}>
            <CharacterSheet character={character}/>
        </CharacterContext.Provider>
    )
}


ReactDOM.render(
    <App />,
    document.getElementById('root')
)


