import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { iCharacterData } from './CharacterData'
import { ACDisplay } from './components/ACDisplay'
import { HealthDisplay } from './components/HealthDisplay'
import { capitaliseFirstLetter } from './Util'
import { Character } from './gameObjects/Character'
import { listOfSkills, skills } from './gameObjects/GameData';
import { CharacterEquipment } from './CharacterSheet/Equipment';
import { CommonButton, CommonHeading, CommonListItem, CommonUl } from './components/Common';
import { CharacterSpells } from './CharacterSheet/Spells';
import { CharacterGauges } from './CharacterSheet/Gauges';

type CharacterSheetSections = 'main'|'stats'

// CSS sheet
const CharacterSheetWrapper = styled.div`
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(29, 17, 18);
    text-align: center;


    .character-main {
        font-size: 24px;
        padding-top: 90px;
    }
    
    h1 {
        font-family: Garamond, Georgia, 'Times New Roman', Times, serif;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    h4 {
        font-family: Garamond, Georgia, 'Times New Roman', Times, serif;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .character-main p {
        font-size: 18px;
        font-family: serif;
    }

    .character-shield-health {
        display: grid;
        grid: 110px / 105px 150px;
        align-items:center; // vertically centers text
        justify-content:center; // horizontally centers text
        
    }

    .text-two-column {
        display: grid;
        grid-template-columns: 150px 150px;
        //grid: auto / 150px 150px;
        //align-items:center; // vertically centers text
        justify-content:center; // horizontally centers text
        
        align-items:center; // vertically centers text
    }

    .custom-boxed-div {
        border-radius: 15px;
        border-color: #3518003b;
        border-width: 3px;
        border-style: dotted;
        background-color: #ffffff1a;
        padding-bottom: 15px;
        margin-top: 10px
    }

    .fixed {
        position: fixed;
    }

    .character-health {
        padding-top: 8px;
    }

    .auto-two-column {
        display: grid;
        justify-content: center;
        align-items:center; // vertically centers text
        grid-template-columns: auto auto;
    }

    .bgred {
        background-color: red;
    }
    .bgpurple {
        background-color: purple;
    }

    .nav-bar {
        background:linear-gradient(-180deg, rgb(108,70,125) 5%, rgb(84,59,98) 100%);
        box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
        height: 80px;
        width: 100%;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }

    .content {
        margin: 8px;
        margin-top: 0px;
    }

    img {
        width: 300px;
    }

    #stats {
        :target::before {
            content: "";
            display: block;
            height: 80px; 
            margin: -80px 0 0;
        }
    }

    #nav-text {
        //background-color: #00800067;
        margin: 0;
        padding: 0;
        list-style-type: none; 
        overflow: hidden;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bolder;
    }

    #nav-text li {
        float: left;
        border-right:1px solid gold;
    }

    #nav-text li a {
        display: block;
        color: gold;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;

        
    }

    #nav-text li:last-child {
        border-right: none;
    }
`

interface iCharacterSheet {
    character: Character;
    //character: iCharacterData
}
export const CharacterSheet = ({character}: iCharacterSheet) => {

    //const [selectedSection, setSelectedSection] = useState<CharacterSheetSections>('main')

    return (
        <Fragment>
            <CharacterSheetWrapper>
                <CharacterOptions character={character}/>
                <div className='content'>
                    <CharacterMain character={character}/>
                    <CharacterStats character={character} />
                </div>
            </CharacterSheetWrapper>
        </Fragment>
    )
}


const CharacterOptions = ({character}: iCharacterSheet) => {




    // DO HEADER & NAV STUFF HEREEEEEEEEEEEEEEE
    return <div className='nav-bar fixed'>
        <ul id="nav-text">
            <li><a href="#main">Main</a></li>
            <li><a href="#stats">Stats</a></li>
            <li><a href="#">Spells</a></li>
            <li><a href="#equipment">Equipment</a></li>
        </ul>
        
    </div>
}

const CharacterMain = ({character}: iCharacterSheet) => {

    let characterClass = capitaliseFirstLetter(character.data.class);

    if (character.data.gender === 'female') {
        switch (character.data.class) {
            case 'sorceror': 
                characterClass = capitaliseFirstLetter('sorceress');
        }
    }

    const levelUp = useCallback(() => {
        character.levelUp()
    }, [character])
    const reset = useCallback(() => {
        character.resetToDefaults();
    }, [character])
    const longRest = useCallback(() => {
        character.longRest();
    }, [character])

    return (
        <div className='character-main' id='main'>
            <img src={character.data.imgUrl} />
            <h1>{character.data.name}</h1>
            <CommonButton onClick={reset}>Reset</CommonButton>
            <CommonButton onClick={levelUp}>Level Up</CommonButton>
            <CommonButton onClick={longRest}>Long Rest</CommonButton>
            <h5>Level {character.currentLevel} {character.data.race} {characterClass}</h5>
            <p><b>Description:</b> {character.data.description}</p>
        </div>
    )
}

const CharacterStats = ({character}: iCharacterSheet) => {

    const increaseHealth = useCallback(() => {
        character.modifyHealth(1)
    }, [character])
    const decreaseHealth = useCallback(() => {
        character.modifyHealth(-1)
    }, [character])

    return (

        <div id='stats'>
            <div className='character-shield-health' >
                <ACDisplay ACValue={character.data.ac} />
                <div className='character-health'>
                    <HealthDisplay
                        currentHealth={character.currentHealth} 
                        maxHealth={character.maxHealth} 
                    />
                    <div className='auto-two-column'>
                        <CommonButton onClick={decreaseHealth} className='wide-button health-button'>-</CommonButton>
                        <CommonButton onClick={increaseHealth} className='wide-button health-button'>+</CommonButton>
                    </div>
                    
                </div>

                
                
            </div>
            <CharacterGauges character={character} />
            <CharacterSavingThrows character={character} />
            <CharacterAttributes character={character} />
            <CharacterSkills character={character} />   
            <CharacterSpells character={character} />
            <CharacterEquipment character={character} />
        </div>
    )
}

const CharacterSavingThrows = ({character}: iCharacterSheet) => {
    return (
        <div className='custom-boxed-div'>
            <CommonHeading>Saving Throws</CommonHeading>
            Strength: {character.getSavingThrow('strength')}<br />
            Dexterity: {character.getSavingThrow('dexterity')}<br />
            Constitution: {character.getSavingThrow('constitution')}<br />
            Intelligence: {character.getSavingThrow('intelligence')}<br />
            Wisdom: {character.getSavingThrow('wisdom')}<br />
            Charisma: {character.getSavingThrow('charisma')}<br />
        </div>
    )
}

const CharacterAttributes = ({character}: iCharacterSheet) => {

    return (
        <div className="character-attributes custom-boxed-div">
            <CommonHeading>Attributes</CommonHeading>
            Strength: {character.data.attributes.strength}<br />
            Dexterity: {character.data.attributes.dexterity}<br />
            Constitution: {character.data.attributes.constitution}<br />
            Intelligence: {character.data.attributes.intelligence}<br />
            Wisdom: {character.data.attributes.wisdom}<br />
            Charisma: {character.data.attributes.charisma}<br />
        </div>
    )
}

const CharacterSkills = ({character}: iCharacterSheet) => {


    //const {skills} = character.data;
    
    const skillsJSX :JSX.Element[] = useMemo( () => {
        let returnedJSX: JSX.Element[] = [];

        for(let skillName of listOfSkills){

            let skillValue = character.getSkillBonus(skillName as skills);

            if (skillName === 'sleightOfHand') skillName = 'Sleight of Hand';
            if (skillName === 'animalHandling') skillName = 'Animal Handling';

            returnedJSX.push(<CommonListItem key={skillName}>{capitaliseFirstLetter(skillName)}: {skillValue}</CommonListItem>)
        }

        return returnedJSX;
    }, [character.currentLevel]);
    

    return (
        <div className="character-skills custom-boxed-div">
            <CommonHeading>Skills</CommonHeading>
            <CommonUl className='text-two-column'>{skillsJSX}</CommonUl>
        </div>
    )
}


