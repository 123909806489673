import React, { createContext, useContext } from 'react';
import { Character } from '../gameObjects/Character';


export const CharacterContext = createContext<{
    characterUpdated: {};
    character?: Character;
}>({
    characterUpdated: {},
})


export const useCharacter = () => {
    return useContext(CharacterContext)
}
