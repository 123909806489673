import { characterClasses, genders, races } from './gameObjects/GameData'


export interface iCharacterData {
    name: string;
    race: races;
    gender: genders;
    class: characterClasses;
    imgUrl?: string;
    description: string;
    level: number;
    ac: number;
    bonuses?: {
        saving_throws?: number;
    }
    savingThrowsProficiency: {
        strength: boolean;
        dexterity: boolean;
        constitution: boolean;
        intelligence: boolean;
        wisdom: boolean;
        charisma: boolean;
    };
    attributes: {
        strength: number;
        dexterity: number;
        constitution: number;
        intelligence: number;
        wisdom: number;
        charisma: number;
    };
    skills?: {
        acrobatics?: number;
        animalHandling?: number;
        arcana?: number;
        athletics?: number;
        deception?: number;
        history?: number;
        insight?: number;
        intimidation?: number;
        investigation?: number;
        medicine?: number;
        nature?: number;
        perception?: number;
        performance?: number;
        persuasion?: number;
        religion?: number;
        sleightOfHand?: number;
        stealth?: number;
        survival?: number;
    };
    skillProficiency: {
        acrobatics?: boolean;
        animalHandling?: boolean;
        arcana?: boolean;
        athletics?: boolean;
        deception?: boolean;
        history?: boolean;
        insight?: boolean;
        intimidation?: boolean;
        investigation?: boolean;
        medicine?: boolean;
        nature?: boolean;
        perception?: boolean;
        performance?: boolean;
        persuasion?: boolean;
        religion?: boolean;
        sleightOfHand?: boolean;
        stealth?: boolean;
        survival?: boolean;
    },
    skillExcellency: {
        acrobatics?: boolean;
        animalHandling?: boolean;
        arcana?: boolean;
        athletics?: boolean;
        deception?: boolean;
        history?: boolean;
        insight?: boolean;
        intimidation?: boolean;
        investigation?: boolean;
        medicine?: boolean;
        nature?: boolean;
        perception?: boolean;
        performance?: boolean;
        persuasion?: boolean;
        religion?: boolean;
        sleightOfHand?: boolean;
        stealth?: boolean;
        survival?: boolean;
    };
    spells: string[];

}

export const characterData: {[name:string]: iCharacterData} = {
    natalia: {
        name: 'Natalia',
        race: 'Yuan-Ti',
        class: 'sorceror',
        gender: 'female',
        ac: 15,
        imgUrl: 'julia.png', 
        description: 'Actually a human that is very normal.',
        level: 1, 
        savingThrowsProficiency: {
            strength: false,
            dexterity: false, 
            constitution: true,
            intelligence: false,
            wisdom: false,
            charisma: true
        }, 
        attributes: {
            strength: 8, 
            dexterity: 14, 
            constitution: 12,
            intelligence: 11,
            wisdom: 14, 
            charisma: 18
        },
        skillProficiency: {
            deception: true,
            insight: true,
            perception: true,
            religion: true,
        },
        skillExcellency: {

        },
        spells: ['infestation', 'bladeWard', 'chromaticOrb', 'prestidigitation', 'rayOfFrost', 'rayOfSickness', 'minorIllusion', 'poisonSpray', 'alterSelf']
    },
    alpha: {
        name: 'Alpha Titanous',
        race: 'Warforged',
        class: 'barbarian',
        gender: 'other',
        ac: 14,
        imgUrl: 'unknown.png', 
        description: 'Actually 3 goblins in a suit.',
        level: 4, 
        bonuses: {
            saving_throws: 2 
        },
        savingThrowsProficiency: {
            strength: true,
            dexterity: false, 
            constitution: true,
            intelligence: false,
            wisdom: false,
            charisma: false,
        }, 
        attributes: {
            strength: 16, 
            dexterity: 8, 
            constitution: 16,
            intelligence: 10,
            wisdom: 16, 
            charisma: 8
        },
        skillProficiency: {
            athletics: true,
            insight: true,
            perception: true,
            intimidation: true,
            investigation: true,
        },
        skillExcellency: {
            investigation: true, //MAKE THIS WORK. Proficiency bonus doubled for excellencies
        },
        spells: []
    }
}


/*
savingThrows: {
    strength: -1,
    dexterity: 2, 
    constitution: 3,
    intelligence: 0,
    wisdom: 2,
    charisma: 6
}, 
*/

/*
skills: {
    acrobatics: 2, 
    animalHandling: 2,
    arcana: 2,
    athletics: -1,
    deception: 6,
    history: 0,
    insight: 4,
    intimidation: 4,
    investigation: 0,
    medicine: 2,
    nature: 0,
    perception: 4,
    performance: 4,
    persuasion: 4,
    religion: 2,
    sleightOfHand: 2,
    stealth: 2,
    survival: 2
},
*/