import React from 'react';
import styled from 'styled-components';

interface iHealthDisplay {
    currentHealth: number;
    maxHealth: number;
}

const HealthWrapper = styled.div`
    width: 100%;
    height: 32px;
    background-color: #d1d0d0;
    box-shadow: 0px 0px 0px 1px #00000050;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    
    z-index: -1;

    .current-health {
        font-family: Garamond, Georgia, 'Times New Roman', Times, serif;
        font-size: 20px;
        
        background-image: linear-gradient(-180deg, #00D775, #00BD68);
        border-radius: 5px;
        height: 100%;
        text-align: center;

        
        font-weight: bold;
        display: flex;
        align-items:center; // vertically centers text
        justify-content:center; // horizontally centers text
        
    }

    .health-text {
        position: absolute;
        top: 10%;
        left: 0px;
        width: 100%;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
        font-size: 21px;
        font-weight: bolder;
        color: #013f1c;
        text-shadow: 1px 1px #ffffffba;
    }
`

export const HealthDisplay = ({currentHealth, maxHealth}: iHealthDisplay) => {

    let healthPercent = `${(currentHealth / maxHealth) * 100}%`

    return (
        <HealthWrapper>
            <div className='current-health' style={{width: healthPercent}}>
            </div>
            <div className='health-text'>
                {currentHealth} / {maxHealth}
            </div>
        </HealthWrapper>
    )
}