import { Fragment, useCallback, useMemo, useState } from "react";
import { FaMagic } from "react-icons/fa";
import { GiSpellBook } from "react-icons/gi"
import styled from "styled-components";
import { iSpellData } from "../gameData/spells";
import { CommonButton, CommonHeading, CommonSubHeading } from "./Common";
import Modal from 'react-modal';
import { updateIf } from "typescript";


interface iSpellDisplay { 
    data: iSpellData;
}
const SpellDisplayWrapper = styled.div`

    padding: 5px;
    position: relative;

    .title {
        font-size: 14px;
        font-weight: 600;
    }

    .casttime-range-duration {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        margin: 5px 0px;
        align-items: center;

        >div {
            overflow: hidden;
        }
    }
    

    &:nth-child(2n) {
        background-color: #00000016;
    }
    &:nth-child(2n+1) {
        background-color: #4137373d;
    }
    
`
export const SpellDisplay = ({data}: iSpellDisplay) => {
    /*
        Creates a "PropertyDisplay" component for each 'property' on the weapon. Eg. Light, Thrown, Finesse
    */

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        if(isModalOpen === false) setModalOpen(true);
    }

    const closeModal = () => {
        if(isModalOpen === true) setModalOpen(false)
    }

    return <SpellDisplayWrapper onClick={openModal}>
        <FaMagic className='icon' />
        <div className='title'>
            {data.name}
        </div>
        <div className="casttime-range-duration">
            <div> {data.castingTime} </div>
            <div> {data.range} </div>
            <div> {data.duration} </div>
        </div>

        <SpellPopup data={data} isOpen={isModalOpen} closeModal={closeModal}/>
        
    </SpellDisplayWrapper>
}

const SpellPopupModal = styled(Modal)`
    height: 100%;
    width: 100%;

    display: grid;
    justify-content: center;
    
    .inner {
        width: fit-content;
        max-width: 80%;
        max-height: 80%;
        height: auto;
        margin: auto;
        margin-top: 90px;
        background-color: #E0C9A6;
        padding: 20px;
        border: 1px solid;
        border-radius: 5px;
        text-align: center;

        .scrollable {
            height: 500px;
            overflow-y: scroll;
        }
        
        >div {
            margin-bottom: 10px;
        }
    }
    
    .description {
        margin: 15px 0px;
    }

    ${CommonButton} {
        display: block;
        margin: auto;
    }

`

interface iSpellPopup {
    data: iSpellData;
    isOpen: boolean;
    closeModal: () => void;
}


const SpellPopup = ({data, isOpen, closeModal}: iSpellPopup) => {


    

    return (
    <SpellPopupModal
        isOpen={isOpen}
        //onRequestClose={()=>{}}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
    >
        <div className='inner'>
            <CommonHeading>{data.name}</CommonHeading>
            <div className='scrollable'>
                <CommonSubHeading>Spell Type: </CommonSubHeading> {data.spellType} <br/>
                <CommonSubHeading>Components: </CommonSubHeading> {data.components}
                <div className='description'>
                    {data.description}
                </div>
                {data.higherLevels? (
                    <Fragment>
                        <CommonSubHeading>Higher Levels: </CommonSubHeading> 
                        <div>
                            {data.higherLevels}
                        </div>
                    </Fragment>
                ) : null}
                
            </div>
            <CommonButton onClick={closeModal}>Close</CommonButton>
        </div>
    </SpellPopupModal>
    )
}