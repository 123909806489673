import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { CommonHeading } from "../components/Common";
import { SpellDisplay } from "../components/SpellDisplay";
import { spellData } from "../gameData/spells";
import { Character } from "../gameObjects/Character";

interface iCharacterSpells {
    character: Character;
}

const CharacterSpellsWrapper = styled.div`

`

export const CharacterSpells = ({character}: iCharacterSpells) => {
    

    // Creates spellsJsx variable which uses the memo function, which makes a memo, so that the app remembers whats contained within it.
    // useMemo(a function: which returns a value to remember/to assign to the variable, an array of properties to watch; if a property changes - the function will run again)
    const spellsJsx = useMemo(() => {
        let new_jsx: JSX.Element[] = [];
        for(let index in character.spells) {
            let spell = character.spells[index];
            /* need to figure out how this works and implement it
            new_jsx.push(<WeaponDisplay key={`weapon_${index}`}data={weapon} onRemove={() => {
                character.removeWeapon(weapon.ref);
            }}/>)
            */
           new_jsx.push(<SpellDisplay key={`spell_${index}`} data={spell} />);
        }
        return new_jsx;
    }, [character.spells.length, character])


    return <CharacterSpellsWrapper className='custom-boxed-div' id="spells">
        <CommonHeading>Spells</CommonHeading>

        <div>
            {spellsJsx}
            
        </div>

    </CharacterSpellsWrapper>
}