import React from 'react';
import styled from 'styled-components';

export const CommonHeading = styled.div`
    font-family: Garamond, Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 7px;
`

export const CommonSubHeading = styled.div`
    font-family: Garamond, Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: inline-flex;
`

export const CommonUl = styled.ul`
    padding: 0px;
`

export const CommonListItem = styled.li`
    margin: 0;
    padding: 0;
    list-style-type: none;
`
export const CommonButton = styled.div`
    cursor: pointer;
    background:linear-gradient(to bottom, rgb(108,70,125) 5%, rgb(84,59,98) 100%);
    border-radius: 5px;
    border-style: none;
    box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
    color: #fff;
    display: inline-block;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 22px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    transition: all .3s cubic-bezier(.05, .03, .35, 1);
    padding: 5px;
    color: #ffdd1d;
    text-shadow:0px 1px 0px #9b14b3;
    font-weight: 600;
    width: 100px;

    margin: 0px 2px;

    &:hover {
        opacity: .7;
    }

    &.wide-button {
        width: 30px;
    }

    &.health-button {
        background-color: initial;
        background-image: linear-gradient(-180deg, #00D775, #00BD68);
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
        color: #FFFFFF;
        cursor: pointer;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        height: 42px;
        line-height: 42px;
        outline: 0;
        overflow: hidden;
        padding: 0 20px;
        pointer-events: auto;
        touch-action: manipulation;
        user-select: none;
        vertical-align: top;
        white-space: nowrap;
        border: 0;
        margin: 5px 2px;
    } 

    &.points-button {
        height: auto;
        padding: 0px;
        font-size: 22px;
        height: 25px;
        line-height: 25px;
        justify-content: center;
        align-items: center;
        margin: auto;
        display: inline-block;
    }

`