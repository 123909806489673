import { useMemo } from "react";
import styled from "styled-components";
import { CommonButton } from "../components/Common";
import { GaugeButton, GaugeDisplay, GaugeWithButtons } from "../components/GaugeDisplay";
import { Character } from "../gameObjects/Character";



interface iCharacterGauges {
    character: Character;
}

const CharacterGaugesWrapper = styled.div`
    
`

export const CharacterGauges = ({character}: iCharacterGauges) => {
    
    let characterGauges = character.gauges;

    const characterGaugesJsx = useMemo(() => {
        let new_jsx: JSX.Element[] = [];
        
        const activeGauges = characterGauges.filter(gauge => {
            const maxGaugeValue = character.getCurrentGaugeMaxValue(gauge.name)
            console.log(gauge.name, maxGaugeValue)
            if(maxGaugeValue > 0) return gauge
        })
        console.log("Active gauges: ", activeGauges)

        for(let index in activeGauges) {
            let gauge = activeGauges[index];

            new_jsx.push(<GaugeWithButtons key={`gauge_${index}`} gauge={gauge} character={character}/>)
        }
        return new_jsx;
    }, [character.gauges.length, character.currentLevel])

    return <CharacterGaugesWrapper>
        {characterGaugesJsx}
    </CharacterGaugesWrapper>
}