import { attributes, classHitDice, classSpecificGauges, races, skillAttributeMapping, skills, iGauge } from './GameData'
import { iCharacterData } from '../CharacterData'
import { iWeaponData } from '../gameData/weapons';
import { iSpellData, spellData } from '../gameData/spells';

/*
    Call the triggerRefresh function whenever an action is taken that should update the page.
        eg. modifyHealth.
        eg. levelUp.
*/

interface iCharacterConstructor {
    data: iCharacterData;
    triggerRefresh: () => void
}

export class Character {
    
    triggerRefresh = () => {}

    data: iCharacterData;

    currentHealth = 0;
    maxHealth = 1;
    currentLevel = 1;

    gauges: iGauge[] = [];

    /*
        {
            'Sorcery Points': 5,
            '1st Lv. Spell Slots': 4,
        } 
     */
    gaugesCurrentValue: {[name: string]: number} = {};

    //an object to contain the characters weapons. Initialized to an empty array by default
    weapons: iWeaponData[] = []

    //an object to contain the characters spells. Initialized to an empty array by default
    spells: iSpellData[] = [];

    constructor({data, triggerRefresh}: iCharacterConstructor) {
        this.triggerRefresh = triggerRefresh;

        this.data = data;
        
        this.currentLevel = data.level;

        this.updateMaxHitPoints();
        this.currentHealth = this.maxHealth;

        this.loadSpells();
        this.loadAllGauges();

    }

    resetToDefaults() {
        //this.currentHealth = this.currentHealth;
        this.weapons = [];
        this.currentLevel = this.data.level;
        this.updateMaxHitPoints();
        this.currentHealth = this.maxHealth;
        this.loadAllGauges();
        this.triggerRefresh()
    }

    /*
        A function that is called whenever the game has a player take a 'Long Rest' option.
    */
    longRest() {
        this.currentHealth = this.maxHealth;
        
        //loop through every gauge and reset to max value if it's reset on a long rest
        for(let name of Object.keys(this.gaugesCurrentValue)){
            const gaugeData = this.gauges.find(gauge => gauge.name === name)

            if(!gaugeData) continue;
            if(!gaugeData.isResetOnLongRest) continue;

            this.gaugesCurrentValue[name] = this.getCurrentGaugeMaxValue(name)
        }

        this.triggerRefresh();
    }
    
    getProficiencyBonus() {
        return Math.ceil(this.currentLevel / 4) + 1
    }

    getAttributeBonus(attributeName: attributes) {
        if (this.data.attributes[attributeName] === undefined) {
            console.error(`${attributeName} does not exist in the character's attributes.`)
            return 0;
        }

        let statValue = this.data.attributes[attributeName];
        return Math.floor(statValue / 2)- 5;
    }

    getSavingThrow(name:attributes) {
        let savingThrow = 0;
        if (this.data.bonuses?.saving_throws) savingThrow += this.data.bonuses?.saving_throws;
        
        if (this.data.savingThrowsProficiency[name]) savingThrow += this.getProficiencyBonus();

        return this.getAttributeBonus(name) + savingThrow;
    }

    getStrengthBonus() {
        return this.getAttributeBonus('strength');
    }

    getSkillBonus(skillName:skills) {
        //get the attribute bonus that is associated with the skill
        // as any tells typescript to treat 'skillAttrbuteMapping' as anything, e.g. 
        let associatedAttribute = skillAttributeMapping[skillName];

        //is the character proficient in the skill?
        let skillProficiency = 0;
        if (this.data.skillProficiency[skillName]) skillProficiency += this.getProficiencyBonus();
        if (this.data.skillExcellency[skillName]) skillProficiency += this.getProficiencyBonus();

        //return the attribute bonus + possible proficiency bonus
        return this.getAttributeBonus(associatedAttribute) + skillProficiency;
    }

    levelUp() {
        this.currentLevel += 1;
        this.updateMaxHitPoints();
        this.triggerRefresh();
    }

    modifyHealth(amount: number) {
        this.currentHealth += amount;
        this.triggerRefresh();
    }

    updateMaxHitPoints() {
        let newMaxHealth = 0;

        
        const hitDice = classHitDice[this.data.class]
        //get the health of character at lv 1
        newMaxHealth = hitDice + this.getAttributeBonus('constitution');

        //get the health of the character after level 1 to current level
        if (this.currentLevel > 1) {
            for (let i = 0; i < this.currentLevel-1; i++) {
                newMaxHealth += (hitDice / 2 + 1) + this.getAttributeBonus('constitution');
            }
            
        }
        this.maxHealth = newMaxHealth;
    }

    /*
        Adds a weapon to the characters equipment
        -Josh
    */
    addWeapon(data: iWeaponData) {
        console.log("Adding weapon data!")
        this.weapons.push(data);
        this.triggerRefresh();
    }

    removeWeapon(ref: string) {
        console.log("Removing weapon ", ref)
        let weaponFound = false;
        this.weapons = this.weapons.filter(weapon => {
            if(weapon.ref !== ref) return weapon;
            if(weaponFound) return weapon;
            weaponFound = true;
        })
        this.triggerRefresh();
    }

    /*
        Adds a spell to the characters equipment
        -copying Josh's prev. code (hopefully I know whawt it's doing)
    */
    addSpell(data: iSpellData) {
        console.log("Adding spell data!")
        this.spells.push(data);
        this.triggerRefresh();
    }

    loadSpells() {

        this.spells = [];

        // add a spell to this.spells based on the name of the spell from the array of spells in character data
        
        for (let spell of this.data.spells) {
            if (!spellData[spell]) {
                console.log('Spellname does not exist!')
                continue; //continue does not execute the code below it will go to the next iteration in the loop
            }
            this.spells.push(spellData[spell]);
        }
    }

    loadAllGauges() {
        this.gauges = classSpecificGauges[this.data.class];
        
        //Initialize the current value for every gauge to be its current max value
        for(let gauge of this.gauges){
            this.gaugesCurrentValue[gauge.name] = this.getCurrentGaugeMaxValue(gauge.name);
        }
    }

    getGauge(nameOfGauge: string) {
        let foundGauge = this.gauges.find(obj => {
            return obj.name === nameOfGauge;
        })

        return foundGauge;
    }

    modifyGaugeCurrentValue(name: string, amount: number) {
        this.gaugesCurrentValue[name] += amount;
        this.triggerRefresh();
    }

    getCurrentGaugeMaxValue(nameOfGauge:string) {
        let currentMaxValue = this.getGauge(nameOfGauge)?.maxValues[this.currentLevel-1];

        if (currentMaxValue === undefined) {
            console.log(`can't find the current max value of gauge`)
            return 0;
        }
        return currentMaxValue;
    }


}
