type DamageTypes = 'bludgeoning' | 'piercing' | 'slashing'
type DiceTypes = 'd4' | 'd6' | 'd8' | 'd10' | 'd12' |'d20'
type CoinType = 'cp' | 'sp' | 'gp' | 'pp'
type WeaponTypes = 'Simple' | 'Simple Ranged' | 'Martial' | 'Martial Ranged'
export type WeaponProperties = 'Light' | 'Finesse' | 'Thrown' | 'Thrown' | 'Versatile' | 
            'Two-Handed' | 'Range' | 'Ammunition' | 'Loading' | 'Reach' | 'Heavy' | 'Special'

export type PropertyData_Thrown = {
    normalRange: number;
    longRange: number;
}
export type PropertyData_Versatile = {
    damage_die: DiceTypes,
    damage_dice: number;
}
export type PropertyData_Range = {
    normalRange: number;
    longRange: number;
}
export type PropertyData_Special = {
    description: string;
}

type WeaponPropertiesData = {
    Thrown?: PropertyData_Thrown,
    Versatile?: PropertyData_Versatile 
    Range?: PropertyData_Range,
    Special?: PropertyData_Special
}

export interface iWeaponData {
    ref: string;
    name: string;
    weapon_type: WeaponTypes;
    weight: string;

    //some weapons have no damage, and only special effects
    damage_type?: DamageTypes;
    damage_dice?: number;
    damage_die?: DiceTypes;

    properties?: WeaponProperties[]
    properties_data?: WeaponPropertiesData
    cost: [number, CoinType]
}

export const weaponData: {[name: string]: iWeaponData} = {
    club: {
        ref: 'club',
        weapon_type: 'Simple',
        name: 'Club',
        damage_type: 'bludgeoning',
        weight: '2 Lbs',
        damage_dice: 1,
        damage_die: 'd4',
        properties: ['Light'],
        cost: [1, 'sp']
    },
    dagger: {
        ref: 'dagger',
        weapon_type: 'Simple',
        name: 'Dagger',
        damage_type: 'piercing',
        weight: '1 Lbs',
        damage_dice: 1,
        damage_die: 'd4',
        cost: [2, 'gp'],
        properties: ['Light', 'Finesse', 'Thrown'],
        properties_data: {
            Thrown: {
                normalRange: 20,
                longRange: 60
            }
        }
    },
    greatClub: {
        ref: 'greatClub',
        weapon_type: 'Simple',
        name: 'Great-Club',
        damage_type: 'bludgeoning',
        weight: '10 Lbs',
        damage_dice: 1,
        damage_die: 'd8',
        cost: [2, 'sp'],
        properties: ['Two-Handed']
    },
    handAxe: {
        ref: 'handAxe',
        weapon_type: 'Simple',
        name: 'Hand-Axe',
        damage_type: 'slashing',
        weight: '2 Lbs',
        damage_dice: 1,
        damage_die: 'd4',
        cost: [5, 'gp'],
        properties: ['Light', 'Thrown'],
        properties_data: {
            Thrown: {
                normalRange: 20,
                longRange: 60
            }
        }
    },
    javelin: {
        ref: 'javelin',
        weapon_type: 'Simple',
        name: 'Javelin',
        damage_type: 'piercing',
        weight: '2 Lbs',
        damage_dice: 1,
        damage_die: 'd6',
        cost: [5, 'sp'],
        properties: ['Thrown'],
        properties_data: {
            Thrown: {
                normalRange: 30,
                longRange: 120
            }
        }
    },
    lightHammer: {
        ref: 'lightHammer',
        weapon_type: 'Simple',
        name: 'Light Hammer',
        damage_type: 'bludgeoning',
        weight: '2 Lbs',
        damage_dice: 1,
        damage_die: 'd4',
        cost: [2, 'gp'],
        properties: ['Light', 'Thrown'],
        properties_data: {
            Thrown: {
                normalRange: 20,
                longRange: 60
            }
        }
    },
    mace: {
        ref: 'mace',
        weapon_type: 'Simple',
        name: 'Mace',
        damage_type: 'bludgeoning',
        weight: '4 Lbs',
        damage_dice: 1,
        damage_die: 'd6',
        cost: [5, 'gp'],
    },
    quarterstaff: {
        ref: 'quarterstaff',
        weapon_type: 'Simple',
        name: 'Quaterstaff',
        damage_type: 'bludgeoning',
        weight: '4 Lbs',
        damage_dice: 1,
        damage_die: 'd6',
        cost: [2, 'sp'],
        properties: ['Versatile'],
        properties_data: {
            Versatile: {
                damage_dice: 1,
                damage_die: 'd8'
            }
        }
    },
    sickle: {
        ref: 'sickle',
        weapon_type: 'Simple',
        name: 'Sickle',
        damage_type: 'slashing',
        weight: '2 Lbs',
        damage_dice: 1,
        damage_die: 'd4',
        cost: [1, 'gp'],
        properties: ['Light'],
    },
    spear: {
        ref: 'spear',
        weapon_type: 'Simple',
        name: 'Spear',
        damage_type: 'piercing',
        weight: '3 lbs',
        damage_dice: 1,
        damage_die: 'd6',
        cost: [1, 'gp'],
        properties: ['Thrown', 'Versatile'],
        properties_data: {
            Thrown: {
                normalRange: 20,
                longRange: 60
            },
            Versatile: {
                damage_dice: 1,
                damage_die: 'd8'
            },
        }
    },
    lightCrossbow: {
        ref: 'lightCrossbow',
        weapon_type: 'Simple Ranged',
        name: 'Light Crossbow',
        damage_type: 'piercing',
        weight: '5 Lbs',
        damage_dice: 1,
        damage_die: 'd8',
        cost: [25, 'gp'],
        properties: ['Range', 'Ammunition', 'Loading', 'Two-Handed'],
        properties_data: {
            Range: {
                normalRange: 80,
                longRange: 320
            }
        }
    },
    dart: {
        ref: 'dart',
        weapon_type: 'Simple Ranged',
        name: 'Dart',
        damage_type: 'piercing',
        weight: '1/4 Lbs',
        damage_dice: 1,
        damage_die: 'd4',
        cost: [5, 'cp'],
        properties: ['Finesse', 'Thrown'],
        properties_data: {
            Thrown: {
                normalRange: 20,
                longRange: 320
            }
        }
    },
    shortbow: {
        ref: 'shortbow',
        weapon_type: 'Simple Ranged',
        name: 'Shortbow',
        damage_type: 'piercing',
        weight: '2 Lbs',
        damage_dice: 1,
        damage_die: 'd6',
        cost: [25, 'gp'],
        properties: ['Range', 'Ammunition', 'Two-Handed'],
        properties_data: {
            Range: {
                normalRange: 80,
                longRange: 320
            }
        }
    },
    sling: {
        ref: 'sling',
        weapon_type: 'Simple Ranged',
        name: 'Sling',
        damage_type: 'piercing',
        weight: '-',
        damage_dice: 1,
        damage_die: 'd4',
        cost: [1, 'sp'],
        properties: ['Range', 'Ammunition'],
        properties_data: {
            Range: {
                normalRange: 30,
                longRange: 120
            }
        }
    },
    battleaxe: {
        weapon_type: 'Martial',
        name: 'Battleaxe',
        ref: 'battleaxe',
        cost: [ 10, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'slashing',
        weight: '4 Lbs',
        properties: [ 'Versatile' ],
        properties_data: {
            Versatile: {
                damage_dice: 1,
                damage_die: 'd10'
            }
        }
      },
      flail: {
        weapon_type: 'Martial',
        name: 'Flail',
        ref: 'flail',
        cost: [ 10, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'bludgeoning',
        weight: '2 Lbs'
      },
      glaive: {
        weapon_type: 'Martial',
        name: 'Glaive',
        ref: 'glaive',
        cost: [ 20, 'gp' ],
        damage_dice: 1,
        damage_die: 'd10',
        damage_type: 'slashing',
        weight: '6 Lbs',
        properties: [ 'Heavy', 'Reach', 'Two-Handed' ]
      },
      greataxe: {
        weapon_type: 'Martial',
        name: 'Greataxe',
        ref: 'greataxe',
        cost: [ 30, 'gp' ],
        damage_dice: 1,
        damage_die: 'd12',
        damage_type: 'slashing',
        weight: '7 Lbs',
        properties: [ 'Heavy', 'Two-Handed' ]
      },
      greatSword: {
        weapon_type: 'Martial',
        name: 'Great-sword',
        ref: 'greatSword',
        cost: [ 50, 'gp' ],
        damage_dice: 2,
        damage_die: 'd6',
        damage_type: 'slashing',
        weight: '6 Lbs',
        properties: [ 'Heavy', 'Two-Handed' ]
      },
      halberd: {
        weapon_type: 'Martial',
        name: 'Halberd',
        ref: 'halberd',
        cost: [ 20, 'gp' ],
        damage_dice: 1,
        damage_die: 'd10',
        damage_type: 'slashing',
        weight: '6 Lbs',
        properties: [ 'Heavy', 'Reach', 'Two-Handed' ]
      },
      lance: {
        weapon_type: 'Martial',
        name: 'Lance',
        ref: 'lance',
        cost: [ 10, 'gp' ],
        damage_dice: 1,
        damage_die: 'd12',
        damage_type: 'piercing',
        weight: '6 Lbs',
        properties: [ 'Reach', 'Special' ],
        properties_data: {
            Special: {
                description: `You have disadvantage when you use a lance to attack a target within 5 feet of you. Also, a lance requires two hands to wield when you aren't mounted.`
            }
        }
      },
      'longSword': {
        weapon_type: 'Martial',
        name: 'Long-sword',
        ref: 'longSword',
        cost: [ 15, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'slashing',
        weight: '3 Lbs',
        properties: [ 'Versatile' ],
        properties_data: {
            Versatile: {
                damage_dice: 1,
                damage_die: 'd10'
            }
        }
      },
      maul: {
        weapon_type: 'Martial',
        name: 'Maul',
        ref: 'maul',
        cost: [ 10, 'gp' ],
        damage_dice: 2,
        damage_die: 'd6',
        damage_type: 'bludgeoning',
        weight: '10 Lbs',
        properties: [ 'Heavy', 'Two-Handed' ]
      },
      morningStar: {
        weapon_type: 'Martial',
        name: 'Morning-star',
        ref: 'morningStar',
        cost: [ 15, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'piercing',
        weight: '4 Lbs'
      },
      pike: {
        weapon_type: 'Martial',
        name: 'Pike',
        ref: 'pike',
        cost: [ 5, 'gp' ],
        damage_dice: 1,
        damage_die: 'd10',
        damage_type: 'piercing',
        weight: '18 Lbs',
        properties: [ 'Heavy', 'Reach', 'Two-Handed' ]
      },
      rapier: {
        weapon_type: 'Martial',
        name: 'Rapier',
        ref: 'rapier',
        cost: [ 25, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'piercing',
        weight: '2 Lbs',
        properties: [ 'Finesse' ]
      },
      scimitar: {
        weapon_type: 'Martial',
        name: 'Scimitar',
        ref: 'scimitar',
        cost: [ 25, 'gp' ],
        damage_dice: 1,
        damage_die: 'd6',
        damage_type: 'slashing',
        weight: '3 Lbs',
        properties: [ 'Finesse', 'Light' ]
      },
      shortSword: {
        weapon_type: 'Martial',
        name: 'Short-sword',
        ref: 'shortSword',
        cost: [ 10, 'gp' ],
        damage_dice: 1,
        damage_die: 'd6',
        damage_type: 'piercing',
        weight: '2 Lbs',
        properties: [ 'Finesse', 'Light' ]
      },
      trident: {
        weapon_type: 'Martial',
        name: 'Trident',
        ref: 'trident',
        cost: [ 5, 'gp' ],
        damage_dice: 1,
        damage_die: 'd6',
        damage_type: 'piercing',
        weight: '4 Lbs',
        properties: [ 'Thrown', 'Versatile' ],
        properties_data: {
            Thrown: {
                normalRange: 20,
                longRange: 60
            },
            Versatile: {
                damage_dice: 1,
                damage_die: 'd8'
            }
        }
      },
      warPick: {
        weapon_type: 'Martial',
        name: 'War pick',
        ref: 'warPick',
        cost: [ 5, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'piercing',
        weight: '2 Lbs'
      },
      warHammer: {
        weapon_type: 'Martial',
        name: 'War-hammer',
        ref: 'warHammer',
        cost: [ 15, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'bludgeoning',
        weight: '2 Lbs',
        properties: [ 'Versatile' ],
        properties_data: {
            Versatile: {
                damage_dice: 1,
                damage_die: 'd10'
            }
        }
      },
      whip: {
        weapon_type: 'Martial',
        name: 'Whip',
        ref: 'whip',
        cost: [ 2, 'gp' ],
        damage_dice: 1,
        damage_die: 'd4',
        damage_type: 'slashing',
        weight: '3 Lbs',
        properties: [ 'Finesse', 'Reach' ]
      },
      blowgun: {
        weapon_type: 'Martial Ranged',
        name: 'Blowgun',
        ref: 'blowgun',
        cost: [ 10, 'gp' ],
        damage_dice: 1,
        damage_type: 'piercing',
        weight: '1 Lbs',
        properties: [ 'Ammunition', 'Range', 'Loading' ],
        properties_data: {
            Range: {
                normalRange: 25,
                longRange: 100
            }
        }
      },
      handCrossbow: {
        weapon_type: 'Martial Ranged',
        name: 'Hand Crossbow',
        ref: 'handCrossbow',
        cost: [ 75, 'gp' ],
        damage_dice: 1,
        damage_die: 'd6',
        damage_type: 'piercing',
        weight: '3 Lbs',
        properties: [ 'Ammunition', 'Range', 'Light', 'Loading' ],
        properties_data: {
            Range: {
                normalRange: 30,
                longRange: 120
            }
        }
      },
      heavyCrossbow: {
        weapon_type: 'Martial Ranged',
        name: 'Heavy Crossbow',
        ref: 'heavyCrossbow',
        cost: [ 50, 'gp' ],
        damage_dice: 1,
        damage_die: 'd10',
        damage_type: 'piercing',
        weight: '18 Lbs',
        properties: [
          'Ammunition',
          'Range',
          'Heavy',
          'Loading',
          'Two-Handed'
        ],
        properties_data: {
            Range: {
                normalRange: 100,
                longRange: 400
            }
        }
      },
      longbow: {
        weapon_type: 'Martial Ranged',
        name: 'Longbow',
        ref: 'longbow',
        cost: [ 50, 'gp' ],
        damage_dice: 1,
        damage_die: 'd8',
        damage_type: 'piercing',
        weight: '2 Lbs',
        properties: [ 'Ammunition', 'Range', 'Heavy', 'Two-Handed' ],
        properties_data: {
            Range: {
                normalRange: 150,
                longRange: 600
            }
        }
      },
      net: {
        weapon_type: 'Martial Ranged',
        name: 'Net',
        ref: 'net',
        cost: [ 1, 'gp' ],
        weight: '3 Lbs',
        properties: [ 'Special', 'Thrown' ],
        properties_data: {
            Thrown: {
                normalRange: 5,
                longRange: 15
            },
            Special: {
                description: 'A Large or smaller creature hit by a net is restrained until it is freed. A net has no effect on creatures that are form less, or creatures that are Huge or larger. A creature can use its action to make a DC 10 Strength check, freeing itself or another creature within its reach on a success. Dealing 5 slashing damage to the net (AC 10) also frees the creature without harming it, ending the effect and destroying the net. When you use an action, bonus action, or reaction to attack with a net, you can make only one attack regardless of the number of attacks you can normally make.'
            }
        }
      }

}

export const PropertyDescriptions: {[property in WeaponProperties]?: string} = {
    Ammunition: `Vou can use a weapon that has the ammunition properly lo make a ranged attack only if you have ammunition to fire from the weapon. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack (you need a free hand to load a one-handed weapon). At the end of the battle, you can recover half your expended ammunition by taking a minute to search the battlefield. If you use a weapon that has the ammunition property to make a melee attack, you treat the weapon as an improvised weapon (see "Improvised Weapons" later in the section). A sling must be loaded to deal any damage when used in this way.`,
    Finesse: `When making an attack with a finesse weapon, you use your choice of your Strength or Dexterity modifier for the attack and damage rolls. You must use the same modifier for both rolls.`,
    Heavy: `Creatures that are Small or Tiny have disadvantage on attack rolls with heavy weapons. A heavy weapon's size and bulk make it too large for a Small or Tiny creature to use effectively.`,
    Light: `A light weapon is small and easy to handle, making it ideal for use when fighting with two weapons.`,
    Loading: `Because of the time required to load this weapon, you can fire only one piece of ammunition from it when you use an action, bonus action, or reaction to fire it, regardless of the number of attacks you can normally make.`,
    Range: `A weapon that can be used to make a ranged attack has a range shown in parentheses after the ammunition or thrown property. The range lists two numbers. The first is the weapon's normal range in feet, and the second indicates the weapon's maximum range. When attacking a target beyond normal range, you have disadvantage on the attack roll. You can't attack a target beyond the weapon's long range.`,
    Reach: `This weapon adds 5 feet to your reach when you attack with it as well as when determining your reach for opportunity attacks with it.`,
    Thrown: `If a weapon has the thrown property, you can throw the weapon to make a ranged attack. If the weapon is a melee weapon, you use the same ability modifier for that attack roll and damage roll that you would use for a melee attack with the weapon. For example, if you throw a handaxe, you use your Strength, but if you throw a dagger, you can use either your Strength or your Dexterity, since the dagger has the finesse property.`,
    "Two-Handed": `This weapon requires two hands when you attack with it.`,
    Versatile: `This weapon can be used with one or two hands. A damage value in parentheses appears with the property the damage when the weapon is used with two hands to make a melee attack.`,
}