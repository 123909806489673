import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useCharacter } from "../Contexts/CharacterContext";
import { Character } from "../gameObjects/Character";
import { iGauge } from "../gameObjects/GameData";

interface iGaugeDisplay {
    gauge: iGauge;
    //characterLevel: number;
}

const GaugeDisplayWrapper = styled.div<{colorFrom:string, colorTo:string}>`
    //width: 55%;
    height: 32px;

    //display: inline;
    background-color: #d1d0d0;
    box-shadow: 0px 0px 0px 1px #00000050;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    //display: inline-block;
    margin: auto 5px;
    
    z-index: -1;

    .title {
        
    }

    .current-points {
        font-family: Garamond, Georgia, 'Times New Roman', Times, serif;
        font-size: 20px;
        
        background-image: linear-gradient(-180deg, ${props => `${props.colorFrom}, ${props.colorTo}`});

        border-radius: 5px;
        height: 100%;
        text-align: center;

        
        font-weight: bold;
        display: flex;
        align-items:center; // vertically centers text
        justify-content:center; // horizontally centers text
        
    }

    .point-text {
        position: absolute;
        top: 10%;
        left: 0px;
        width: 100%;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
        font-size: 21px;
        font-weight: bolder;
        color: #013f1c;
        text-shadow: 1px 1px #ffffffba;
    }
`

interface iGaugeWithButtons {
    gauge: iGauge;
    character: Character;
}
const GaugeWithButtonsWrapper = styled.div`

    .title {
        font-size: 17px;
        margin: 2px 0px;
    }

    .buttons-row {
        display: grid;
        grid-template-columns: auto 1fr auto;
        width: 75%;
        margin: auto;
    }
`
export const GaugeWithButtons = ({gauge, character}: iGaugeWithButtons) => {

    //test stuff
    const decreasePoint = () => {
        character.modifyGaugeCurrentValue(gauge.name, -1)
        console.log('decreasing point... noooot ');
    }

    // test stuff
    const increasePoint = () => {
        character.modifyGaugeCurrentValue(gauge.name, 1)
        console.log('increasing point.... noooot')
    }


    return (
        <GaugeWithButtonsWrapper>
            <div className="title">{gauge.name}</div>
            <div className="buttons-row">
                <GaugeButton colorFrom={gauge.colorFrom} colorTo={gauge.colorTo} onClick={decreasePoint}>-</GaugeButton>
                <GaugeDisplay gauge={gauge}/>
                <GaugeButton colorFrom={gauge.colorFrom} colorTo={gauge.colorTo} onClick={increasePoint}>+</GaugeButton>
            </div>
        </GaugeWithButtonsWrapper>
    )
}

// send in the character, and the name of the gauge

export const GaugeDisplay = ({gauge}: iGaugeDisplay) => {

    const { character } = useCharacter();

    // update this value whenever 'characterUpdated' changes.
    let maxValue = useMemo(() => {
        if(!character) return 0;
        return gauge.maxValues[character.currentLevel-1];
    }, [(character && character.currentLevel)])

    // update this value whenever 'characterUpdated' changes.
    let currentValue = useMemo(() => {
        if(!character) return 0;
        return character.gaugesCurrentValue[gauge.name]
    }, [(character && character.gaugesCurrentValue[gauge.name])]) 


    let gaugePercent = `${(currentValue / maxValue) * 100}%`

    return(
        <GaugeDisplayWrapper colorFrom={gauge.colorFrom} colorTo={gauge.colorTo}>
            
            <div className = 'current-points' style={{width: gaugePercent}}>
                
            </div>
            <div className='point-text'>
                {currentValue} / {maxValue}
            </div>
        </GaugeDisplayWrapper>
    )
    
} 

export const GaugeButton = styled.div<{colorFrom:string, colorTo:string}>`
    cursor: pointer;
        //display: inline-block;
        background-image: linear-gradient(-180deg, ${props => `${props.colorFrom}, ${props.colorTo}`});
        border-radius: 5px;
        border-style: none;
        box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;

        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 22px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding: 2px;
        text-shadow:0px 1px 0px #9b14b3;
        font-weight: 600;
        width: 40px;

        box-shadow: 0px 0px 0px 1px #00000050;
        
        position: relative;
        //bottom: 13px;



        color: white;
        text-shadow: 1px 1px black;

        margin: 5px 2px;

        &:hover {
            opacity: .7;
        }

`
    