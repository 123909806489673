import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { CommonHeading } from "../components/Common";
import { WeaponDisplay } from "../components/WeaponDisplay";
import { weaponData } from "../gameData/weapons";
import { Character } from "../gameObjects/Character";
import Select from 'react-select';

interface iCharacterEquipment {
    character: Character
}
const CharacterEquipmentWrapper = styled.div`
    :target::before {
        content: "";
        display: block;
        height: 80px; 
        margin: -80px 0 0;
    }
`
export const CharacterEquipment = ({character}: iCharacterEquipment) => {

    const weaponsJsx = useMemo(() => {
        let new_jsx: JSX.Element[] = [];
        for(let index in character.weapons) {
            let weapon = character.weapons[index];
            new_jsx.push(<WeaponDisplay key={`weapon_${index}`}data={weapon} onRemove={() => {
                character.removeWeapon(weapon.ref);
            }}/>)
        }
        return new_jsx;
    }, [character.weapons.length, character])

    return <CharacterEquipmentWrapper className="custom-boxed-div" id="equipment">
        <CommonHeading>Equipment</CommonHeading>
        <div>
            {weaponsJsx}
        </div>
        <AddCharacterEquipment character={character}/>
    </CharacterEquipmentWrapper>
}

interface iAddCharacterEquipment {
    character: Character
}
const AddCharacterEquipmentWrapper = styled.div`

    .select-wrapper {
        margin: 10px 20px 0px 20px;
    }
`
type SelectOption = {value: string, label: string}
const AddCharacterEquipment = ({character}: iAddCharacterEquipment) => {

    //creates an array of options in the "React Select" required format, based on the weaponData
    const options = useMemo(() => {
        let new_options = []

        //add an option for each weapon into a 'group' based on the weapons type. Eg simple, ranged, martial
        const grouped_options: {[weapon_type: string]: SelectOption[]} = {}
        for(let [ref, data] of Object.entries(weaponData)) {
            grouped_options[data.weapon_type] = grouped_options[data.weapon_type] || []
            grouped_options[data.weapon_type].push({
                value: ref,
                label: data.name
            })
        }

        //build the grouped-options array from the object of {[weapon_type]: SelectOption[]}
        for(let [weapon_type, typed_options] of Object.entries(grouped_options)) {
            new_options.push({
                label: weapon_type,
                options: typed_options
            })
        }

        return new_options;
    }, [])

    const HandleSelect = (selected: SelectOption) => {
        if(!selected.value) return;
        console.log("Handling select")

        character.addWeapon(weaponData[selected.value])
    }

    return <AddCharacterEquipmentWrapper>
        <div className="select-wrapper">
            <Select value={null} onChange={(selectedOption) => {
                if (Array.isArray(selectedOption)) {
                    throw new Error("Unexpected type passed to ReactSelect HandleSelect handler")
                }

                if(!selectedOption) return;

                //for some reason... need to override this to be a SelectOption because Typescript doesn't think it will be..
                HandleSelect(selectedOption as unknown as SelectOption)

            }} menuPlacement="auto" options={options}/>
        </div>
    </AddCharacterEquipmentWrapper>
}


