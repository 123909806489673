export type races = 'Human' | 'Yuan-Ti' | 'Warforged' | 'Dragonborn' | 'Dwarf' | 'Elf' | 'Gnome' | 'Half-Elf' | 
'Half-Orc' | 'Halfling' | 'Tiefling';
export type attributes = 'strength' | 'dexterity' | 'constitution' | 'intelligence' | 'wisdom' | 'charisma';
export type skills = 'acrobatics' | 'animalHandling' | 'arcana' | 'athletics' | 'deception' | 
'history' | 'insight' | 'intimidation' | 'investigation' | 'medicine' | 'nature' | 'perception' | 
'performance' | 'persuasion' | 'religion' | 'sleightOfHand' | 'stealth' | 'survival';
export type characterClasses  = 'barbarian' | 'sorceror' | 'artificer' | 'bard' | 'cleric' | 'druid' | 
'fighter' | 'monk' | 'paladin' | 'ranger' | 'rogue' | 'warlock' | 'wizard';
export type genders = 'male' | 'female' | 'other';

export const listOfSkills = ['acrobatics', 'animalHandling', 'arcana', 'athletics', 'deception', 'history', 
'insight', 'intimidation', 'investigation', 'medicine', 'nature', 'perception', 'performance', 'persuasion',
'religion', 'sleightOfHand', 'stealth', 'survival']

export const skillAttributeMapping: {[skill in skills]: attributes} = {
    acrobatics: 'dexterity',
    animalHandling: 'wisdom',
    arcana: 'intelligence',
    athletics: 'strength',
    deception: 'charisma',
    history: 'intelligence',
    insight: 'wisdom',
    intimidation: 'charisma',
    investigation: 'intelligence',
    medicine: 'wisdom',
    nature: 'intelligence',
    perception: 'wisdom',
    performance: 'charisma',
    persuasion: 'charisma',
    religion: 'intelligence',
    sleightOfHand: 'dexterity',
    stealth: 'dexterity',
    survival: 'wisdom',
};

export const classHitDice: {[characterClass in characterClasses]: number} = {
    barbarian: 12,
    sorceror: 6,
    artificer: 8,
    bard: 8,
    cleric: 8,
    druid: 8,
    fighter: 10,
    monk: 8,
    paladin: 10,
    ranger: 10,
    rogue: 8,
    warlock: 8,
    wizard: 6
}

export interface iGauge {
    name: string;
    /**
     * an array holding the maximunm value the character can have at each level 
     * (indexed by character's lv-1, i.e. lv 1 = maxValues[0], lv 2 = maxValues[1] etc)
     */
    maxValues: number[]; 
    colorFrom: string;
    colorTo: string;
    isResetOnLongRest: boolean;
}


/* Using palette's from: 
* https://coolors.co/palette/ef476f-ffd166-06d6a0-118ab2-073b4c
* https://coolors.co/palette/ff595e-ffca3a-8ac926-1982c4-6a4c93
*
*/
export const classSpecificGauges: {[characterClass in characterClasses]: iGauge[]} = {
    barbarian: [{
        name: 'Rages',
        maxValues: [2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 6, 6, 6, 0],
        colorFrom: '#FF7059',
        colorTo: '#FF3E45',
        isResetOnLongRest: true
    }],
    sorceror: [{
        name: 'Sorcery Points',
        maxValues: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        colorFrom: '#F26B8B',
        colorTo: '#EF476F',
        isResetOnLongRest: true
    }, {
        name: '1st Lv. Spell Slots',
        maxValues: [2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '2nd Lv. Spell Slots',
        maxValues: [0, 0, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }/*, {
        name: '3rd Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '4th Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 0, 0, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '5th Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '6th Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '7th Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 2],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '8th Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }, {
        name: '9th Lv. Spell Slots',
        maxValues: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1],
        colorFrom: '#06D6A0',
        colorTo: 'teal',
        isResetOnLongRest: true
    }*/],
    artificer: [],
    bard: [],
    cleric: [],
    druid: [],
    fighter: [],
    monk: [],
    paladin: [],
    ranger: [],
    rogue: [],
    warlock: [],
    wizard: []
}
